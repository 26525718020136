// Generated by ./scripts/gen_icons.js
export const IconArrowLeft = () => (
  <svg x="0px" y="0px" viewBox="0 0 24 24">
    <path
      d="M12,20c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h11.6l-5.3-5.3
	c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l7,7c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4l0,0c0,0,0,0,0,0l0,0c0,0.1,0,0.3-0.1,0.4
	c0,0.1-0.1,0.2-0.2,0.3l-7,7C12.5,19.9,12.3,20,12,20z"
    />
  </svg>
);
export const IconArrowNarrowRight = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 12H20M20 12L14 6M20 12L14 18"
      stroke="#113051"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconAsterisk = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4V20M18 6L6 18M20 12H4M18 18L6 6"
      stroke="#004EEB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconCheckHeart = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 11L11 13L15.5 8.5M11.9932 5.13581C9.9938 2.7984 6.65975 2.16964 4.15469 4.31001C1.64964 6.45038 1.29697 10.029 3.2642 12.5604C4.75009 14.4724 8.97129 18.311 10.948 20.0749C11.3114 20.3991 11.4931 20.5613 11.7058 20.6251C11.8905 20.6805 12.0958 20.6805 12.2805 20.6251C12.4932 20.5613 12.6749 20.3991 13.0383 20.0749C15.015 18.311 19.2362 14.4724 20.7221 12.5604C22.6893 10.029 22.3797 6.42787 19.8316 4.31001C17.2835 2.19216 13.9925 2.7984 11.9932 5.13581Z"
      stroke="#099250"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconCheckVerified = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 12L11 14L15.5 9.5M17.9012 4.99851C18.1071 5.49653 18.5024 5.8924 19.0001 6.09907L20.7452 6.82198C21.2433 7.02828 21.639 7.42399 21.8453 7.92206C22.0516 8.42012 22.0516 8.97974 21.8453 9.47781L21.1229 11.2218C20.9165 11.7201 20.9162 12.2803 21.1236 12.7783L21.8447 14.5218C21.9469 14.7685 21.9996 15.0329 21.9996 15.2999C21.9997 15.567 21.9471 15.8314 21.8449 16.0781C21.7427 16.3249 21.5929 16.549 21.4041 16.7378C21.2152 16.9266 20.991 17.0764 20.7443 17.1785L19.0004 17.9009C18.5023 18.1068 18.1065 18.5021 17.8998 18.9998L17.1769 20.745C16.9706 21.2431 16.575 21.6388 16.0769 21.8451C15.5789 22.0514 15.0193 22.0514 14.5212 21.8451L12.7773 21.1227C12.2792 20.9169 11.7198 20.9173 11.2221 21.1239L9.47689 21.8458C8.97912 22.0516 8.42001 22.0514 7.92237 21.8453C7.42473 21.6391 7.02925 21.2439 6.82281 20.7464L6.09972 19.0006C5.8938 18.5026 5.49854 18.1067 5.00085 17.9L3.25566 17.1771C2.75783 16.9709 2.36226 16.5754 2.15588 16.0777C1.94951 15.5799 1.94923 15.0205 2.1551 14.5225L2.87746 12.7786C3.08325 12.2805 3.08283 11.7211 2.8763 11.2233L2.15497 9.47678C2.0527 9.2301 2.00004 8.96568 2 8.69863C1.99996 8.43159 2.05253 8.16715 2.15472 7.92043C2.25691 7.67372 2.40671 7.44955 2.59557 7.26075C2.78442 7.07195 3.00862 6.92222 3.25537 6.8201L4.9993 6.09772C5.49687 5.89197 5.89248 5.4972 6.0993 5.00006L6.82218 3.25481C7.02848 2.75674 7.42418 2.36103 7.92222 2.15473C8.42027 1.94842 8.97987 1.94842 9.47792 2.15473L11.2218 2.87712C11.7199 3.08291 12.2793 3.08249 12.7771 2.87595L14.523 2.15585C15.021 1.94966 15.5804 1.9497 16.0784 2.15597C16.5763 2.36223 16.972 2.75783 17.1783 3.25576L17.9014 5.00153L17.9012 4.99851Z"
      stroke="#175CD3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconCheckmark = () => (
  <svg x="0px" y="0px" viewBox="0 0 24 24">
    <path
      d="M9,18c-0.3,0-0.5-0.1-0.7-0.3l-5-5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L9,15.6L19.3,5.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4
	l-11,11C9.5,17.9,9.3,18,9,18z"
    />
  </svg>
);
export const IconChevronRight = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 15L12.5 10L7.5 5"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconClock = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke="#F79009"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconCross = () => (
  <svg x="0px" y="0px" viewBox="0 0 20 20">
    <g>
      <path d="M15,5L5,15 M5,5l10,10" />
      <path
        d="M15,15.8c-0.2,0-0.4-0.1-0.6-0.2L10,11.2l-4.4,4.4c-0.3,0.3-0.9,0.3-1.2,0c-0.3-0.3-0.3-0.9,0-1.2L8.8,10L4.4,5.6
		c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0L10,8.8l4.4-4.4c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2L11.2,10l4.4,4.4
		c0.3,0.3,0.3,0.9,0,1.2C15.4,15.8,15.2,15.8,15,15.8z"
      />
    </g>
  </svg>
);
export const IconError = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#D92D20">
    <path d="M12,22.9c-.1,0-.3,0-.4,0-.3,0-.5-.2-.8-.4l-7.4-4.1c-.4-.2-.6-.3-.8-.6-.2-.2-.3-.4-.4-.7-.1-.3-.1-.6-.1-1V7.9c0-.4,0-.7.1-1,0-.3.2-.5.4-.7.2-.2.5-.4.8-.6L10.7,1.6c.3-.2.6-.3.9-.4.3,0,.5,0,.8,0,.3,0,.5.2.9.4l7.4,4.1c.3.2.6.3.8.6.2.2.3.5.4.7,0,.3,0,.6,0,1v8.1c0,.4,0,.7-.1,1,0,.3-.2.5-.4.7-.2.2-.5.4-.8.6l-7.4,4.1c-.3.2-.6.3-.9.4-.1,0-.3,0-.4,0ZM12,3.1s-.1,0-.3.2l-7.4,4.1c-.2,0-.3.1-.3.2,0,0,0,.2,0,.3v8.1c0,.2,0,.3,0,.4,0,0,.1,0,.3.1l7.4,4.1c.2,0,.2.1.3.2,0,0,.1,0,.3-.2l7.4-4.1c.2,0,.3-.1.3-.2,0,0,0-.2,0-.3V7.9c0-.2,0-.3,0-.4,0,0-.1,0-.3-.1l-7.4-4.1c-.1,0-.2-.1-.3-.2ZM12,17c-.6,0-1-.4-1-1s.4-1,1-1h0c.6,0,1,.4,1,1s-.4,1-1,1ZM12,13c-.6,0-1-.4-1-1v-4c0-.6.4-1,1-1s1,.4,1,1v4c0,.6-.4,1-1,1Z" />
  </svg>
);
export const IconHourglass = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 12L7.72711 8.43926C7.09226 7.91022 6.77484 7.6457 6.54664 7.32144C6.34444 7.03413 6.19429 6.71354 6.10301 6.37428C6 5.99139 6 5.57819 6 4.7518V2M12 12L16.2729 8.43926C16.9077 7.91022 17.2252 7.6457 17.4534 7.32144C17.6556 7.03413 17.8057 6.71354 17.897 6.37428C18 5.99139 18 5.57819 18 4.7518V2M12 12L7.72711 15.5607C7.09226 16.0898 6.77484 16.3543 6.54664 16.6786C6.34444 16.9659 6.19429 17.2865 6.10301 17.6257C6 18.0086 6 18.4218 6 19.2482V22M12 12L16.2729 15.5607C16.9077 16.0898 17.2252 16.3543 17.4534 16.6786C17.6556 16.9659 17.8057 17.2865 17.897 17.6257C18 18.0086 18 18.4218 18 19.2482V22M4 2H20M4 22H20"
      stroke="#DC6803"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconInfo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12,23c-6.1,0-11-4.9-11-11S5.9,1,12,1s11,4.9,11,11-4.9,11-11,11ZM12,3C7,3,3,7,3,12s4,9,9,9,9-4,9-9S17,3,12,3ZM12,17c-.6,0-1-.4-1-1v-4c0-.6.4-1,1-1s1,.4,1,1v4c0,.6-.4,1-1,1ZM12,9c-.6,0-1-.4-1-1s.4-1,1-1h0c.6,0,1,.4,1,1s-.4,1-1,1Z" />
  </svg>
);
export const IconMinus = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 12H19"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconThumbsUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M17.4,23H4c-1.7,0-3-1.3-3-3v-7c0-1.7,1.3-3,3-3h2.4l3.5-7.9c.3-.7.9-1.1,1.7-1.1,1.9,0,3.5,1.6,3.5,3.5v3.5h3.5c1.2,0,2.3.5,3,1.4s1.1,2.1.9,3.2l-1.1,7c-.3,2-2,3.4-4,3.4ZM8,21h9.4c1,0,1.8-.7,2-1.7l1.1-7c0-.6,0-1.2-.5-1.6-.4-.4-.9-.7-1.5-.7h-3.5c-1.1,0-2-.9-2-2v-3.5c0-.8-.6-1.4-1.4-1.5l-3.5,7.8c0,.1-.1.2-.2.3v9.9ZM4,12c-.6,0-1,.4-1,1v7c0,.6.4,1,1,1h2v-9h-2Z" />
  </svg>
);
export const IconWarning = () => (
  <svg x="0px" y="0px" viewBox="0 0 24 24">
    <path
      d="M20.2,21.5H3.8c-1.1,0-1.7,0-2.2-0.4c-0.5-0.3-0.8-0.8-0.8-1.4c-0.1-0.6,0.2-1.2,0.8-2.1L9.8,3.4l0,0l0.9,0.5L9.8,3.4
	c0.5-0.9,0.8-1.5,1.4-1.7c0.5-0.2,1.1-0.2,1.6,0c0.6,0.3,0.9,0.8,1.4,1.7l8.2,14.2c0.5,0.9,0.8,1.5,0.8,2.1
	c-0.1,0.6-0.4,1.1-0.8,1.4C21.9,21.5,21.3,21.5,20.2,21.5z M2.8,19.5c0.2,0,0.6,0,1,0h16.4c0.4,0,0.8,0,1,0
	c-0.1-0.2-0.3-0.5-0.5-0.9L12.5,4.4c-0.2-0.3-0.4-0.7-0.5-0.9c-0.1,0.2-0.3,0.5-0.5,0.9l-0.9-0.5l0.9,0.5L3.3,18.6
	C3.1,18.9,2.9,19.3,2.8,19.5z M12,18c-0.6,0-1-0.4-1-1s0.4-1,1-1h0c0.6,0,1,0.4,1,1S12.6,18,12,18z M12,14c-0.6,0-1-0.4-1-1V9
	c0-0.6,0.4-1,1-1s1,0.4,1,1v4C13,13.6,12.6,14,12,14z"
    />
  </svg>
);
